const initialState = {
    badges:[]
};

function Badges(state = initialState,action) {
    
      switch(action.type){
        case 'SET_BADGES':
            return {badges:action.payload.data}
        case 'ROLLBACK_BADGES':
        console.log("i didn't get called");
           return {};
        default:
            return state;
    }   
}

export default Badges;