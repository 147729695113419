const initialState = {
    tripInfo:[]//localStorage.getItem('tripInfo')? localStorage.getItem('tripInfo') : []
};

function RecentTrips(state = initialState,action) {
    let tripInfo = localStorage.getItem('reduxPersist:RecentTrips') ? JSON.parse(localStorage.getItem('reduxPersist:RecentTrips')): {};
    switch(action.type){
        case 'SET_RECENT_TRIPS':
            let data = action.payload.data.length > 0 ? action.payload.data.reverse(): [];
            return {tripInfo:data,isLoading:false};
        case 'ROLLBACK_RECENT_TRIPS':
            return tripInfo;
        case 'SET_RECENT_TRIP_LOADER_ACTIVE':
            return {tripInfo, isLoading:true};
        case 'SET_RECENT_TRIP_LOADER_DEACTIVE':
            return {tripInfo, isLoading:false};
        default:
            return state;
    }
}

export default RecentTrips;