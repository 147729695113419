import React, { Fragment } from "react";
import ProgressiveBars from "./ProgressiveBars";
import MapIncident from "./MapIncident";
import { withTranslation } from "react-i18next";
import fetchIncident from "../../../store/Actions/FETCH_INCIDENTS";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Loader from "../../../Common/Loader";

class mainIncidentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleIncidents: [],
      map_data: [],
      incident_data: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.getIncidents(this.props.tripId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.incidents !== this.props.incidents) {
      if (
        this.props.incidents !== undefined &&
        this.props.incidents !== "" &&
        this.props.incidents[this.props.tripId] !== undefined &&
        this.props.incidents[this.props.tripId] !== "" &&
        this.props.incidents[this.props.tripId].data !== undefined
      ) {
        this.setState({
          map_data: this.props.incidents[this.props.tripId].data.Incidents,
          incident_data:
            this.props.incidents[this.props.tripId].data.ScoreComponents,
          isLoading: false,
        });
      }
    }
    if (prevProps.tripId !== this.props.tripId) {
      this.props.getIncidents(this.props.tripId);
    }
  }

  resetIncident = () => {
    const incident_data = [...this.state.incident_data];
    for (var i = 0; i < incident_data.length; i++) {
      incident_data[i].isVisible = false;
    }
    this.setState({
      visibleIncidents: [],
      incident_data,
    });
  };

  showIncidents = (incidentName) => {
    const incident_data = this.state.incident_data;
    for (var i = 0; i < incident_data.length; i++) {
      if (incident_data[i].IncidentName === incidentName) {
        incident_data[i].isVisible = true;
        break;
      }
    }
    this.setState({
      visibleIncidents: [...this.state.visibleIncidents, incidentName],
      incident_data,
    });
  };

  hideIncidents = (incidentName) => {
    const incident_data = this.state.incident_data;
    const visibleIncidents = this.state.visibleIncidents;
    visibleIncidents.splice(
      this.state.visibleIncidents.indexOf(incidentName),
      1
    );
    for (var i = 0; i < incident_data.length; i++) {
      if (incident_data[i].IncidentName === incidentName) {
        incident_data[i].isVisible = false;
        break;
      }
    }
    this.setState({
      visibleIncidents,
      incident_data,
    });
  };

  render() {
    const { t } = this.props;
    const isLoading = this.state.isLoading;
    let map_data = this.state.map_data;
    let incident_data = this.state.incident_data;
    const visibleIncidents = this.state.visibleIncidents;
    let filtered_map_data = map_data;
    if (visibleIncidents.length > 0) {
      filtered_map_data = map_data.filter(
        (plot) => visibleIncidents.indexOf(plot.EventId) > -1
      );
    }
    let incidentColorArray = [
      "#e6194B",
      "#ffe119",
      "#bfef45",
      "#42d4f4",
      "#4363d8",
      "#911eb4",
      "#f032e6",
      "#a9a9a9",
      "#808000",
      "#800000",
      "#000075",
      "#ffd8b1",
      "#aaffc3",
      "#469990",
      "#fabebe",
      "#e6beff",
      "#9A6324",
      "#fffac8",
      "#2096BA",
      "#3DDAD7",
    ];
    return (
      <Fragment>
        {isLoading ? (
          <Loader />
        ) : map_data !== null &&
          incident_data !== null &&
          map_data !== undefined &&
          incident_data !== undefined &&
          map_data.length > 0 &&
          incident_data.length > 0 ? (
          <div
            style={{ touchAction: "none" }}
            onTouchStart={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <MapIncident
              map_data={filtered_map_data}
              incident_data={incident_data}
              visibleIncidents={visibleIncidents}
              incidentColorArray={incidentColorArray}
            />
            <div className="clearfix"></div>
            <ProgressiveBars
              incident_data={incident_data}
              showIncidents={this.showIncidents}
              hideIncidents={this.hideIncidents}
              incidentColorArray={incidentColorArray}
            />
          </div>
        ) : (
          <Row className="incidents_content">
            <Col style={{ textAlign: "justify" }}>
              {t("incidents_lb_noDataMessage")}
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const IncidentContainer = withTranslation()(mainIncidentContainer);

const mapStateToProps = (state) => {
  return {
    incidents: state.Incidents.incidents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidents: (tripId) => dispatch(fetchIncident(tripId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IncidentContainer);
