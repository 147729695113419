import React, { Fragment } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import EYE_NORMAL from "../../../assets/ico_eye_normal.svg";
import EYE_ACTIVE from "../../../assets/ico_eye_active.svg";
import { useTranslation } from "react-i18next";

function generateOrangeBar(value) {
  if (value > 0) {
    return (
      <Fragment>
        <div
          className="progress-bar"
          style={{
            width: value * 100 + "%",
            height: "14px",
            backgroundColor: "#cedce3",
            background:
              "transparent linear-gradient(90deg, #DC7800 0%, #E6A04C 100%) 0% 0% no-repeat padding-box",
          }}
        ></div>
        <div
          className="progress-bar"
          style={{
            width: (1 - value) * 100 + "%",
            height: "14px",
            backgroundColor: "#cedce3",
          }}
        ></div>
      </Fragment>
    );
  } else {
    return (
      <div
        className="progress-bar"
        style={{ width: "100%", height: "14px", backgroundColor: "#cedce3" }}
      ></div>
    );
  }
}

function generateGreenBar(value) {
  if (value < 0) {
    return (
      <Fragment>
        <div
          className="progress-bar"
          style={{
            width: Math.abs(value) * 100 + "%",
            height: "14px",
            backgroundColor: "#cedce3",
            background:
              "transparent linear-gradient(90deg, #009B3C 0%, #4CB976 100%) 0% 0% no-repeat padding-box",
          }}
        ></div>
        <div
          className="progress-bar"
          style={{
            width: (1 - Math.abs(value)) * 100 + "%",
            height: "14px",
            backgroundColor: "#cedce3",
          }}
        ></div>
      </Fragment>
    );
  } else {
    return (
      <div
        className="progress-bar"
        style={{ width: "100%", height: "14px", backgroundColor: "#cedce3" }}
      ></div>
    );
  }
}

function ProgressiveBars(props) {
  const data = props.incident_data;
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="DB_ProgressiveCon INP_progressiveCon">
        {data.map((value, k) => {
          return value.IncidentName !== undefined &&
            value.IncidentName !== null &&
            value.IncidentName !== "" ? (
            <Row key={k}>
              <Col
                xs={1}
                style={{ padding: "0", textAlign: "center", marginTop: "2px" }}
              >
                {" "}
                <span
                  className="serial_background"
                  style={{
                    backgroundColor:
                      k < 20 ? props.incidentColorArray[k] : "#000000",
                  }}
                >
                  {k + 1}
                </span>
              </Col>
              <Col xs={5} style={{ padding: "0 0 0 5px" }}>
                <div
                  className="progress"
                  style={{
                    height: "10px",
                    borderRadius: "10px",
                    marginTop: "4px",
                  }}
                >
                  {value.IncidentValue > 0
                    ? generateOrangeBar(value.IncidentValue)
                    : generateGreenBar(value.IncidentValue)}
                </div>
              </Col>
              <Col xs={5} style={{ padding: "0 10px" }}>
                <p className="text-primary text-break">
                  {t([value.IncidentName.toLowerCase()])}
                  <Badge variant="light">{value.Occurence}</Badge>
                </p>
              </Col>
              <Col
                xs={1}
                style={{
                  padding: "0",
                  height: "20px",
                  maxWidth: "22px !important",
                }}
              >
                {value.isVisible ? (
                  <img
                    src={EYE_ACTIVE}
                    className="img"
                    alt="active_incident"
                    onClick={() => props.hideIncidents(value.IncidentName)}
                  />
                ) : (
                  <img
                    src={EYE_NORMAL}
                    className="img"
                    alt="hidden_incident"
                    onClick={() => props.showIncidents(value.IncidentName)}
                  />
                )}
              </Col>
              <hr className="incident_seperator" />
            </Row>
          ) : null;
        })}
      </div>
    </Fragment>
  );
}

export default ProgressiveBars;
