import React, { Component, Fragment } from 'react';
import MainSlider from './Components/MainSlider';
import './Dashboard.css'
import { Helmet } from 'react-helmet';

class Dashboard extends Component {

    render() {
        return (
            <Fragment>
                <Helmet>
                <title>Dashboard Page</title>
                <meta
                    name="description"
                    content="A Linde driver companion application dashboard"
                />
                </Helmet>
                <MainSlider {...this.props}/>
            </Fragment>
        )
    }
}

export default Dashboard
