import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import Tree from "./Components/Tree";
import Details from "./Components/Details";
import Badges from "./Components/Badges";
import fetchAchievements from "../../store/Actions/FETCH_ACHIEVEMENTS";
import { connect } from "react-redux";
import Loader from "../../Common/Loader";

class Achievements extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    if (this.props.driverId !== undefined) {
      this.props.getAchievements(this.props.driverId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.driverId !== this.props.driverId) {
      if (this.props.driverId !== undefined) {
        this.props.getAchievements(this.props.driverId);
      }
    }
  }
  render() {
    let achievements = this.props.achievements;
    const isLoading =
      this.props.isLoading !== undefined
        ? this.props.isLoading
        : this.state.isLoading;
    return (
      <Fragment>
        <Helmet>
          <title>Achievements Page</title>
          <meta
            name="description"
            content="A Linde driver companion application Driver Achievements"
          />
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <Row className="ACH-TreeDetalisCon">
              <Col className="ACH-TreeDetalisImg-lft">
                <Tree
                  leafScore={
                    achievements.LeafScore !== undefined
                      ? achievements.LeafScore
                      : 0
                  }
                />
              </Col>
              {Object.keys(achievements).length > 0 ? (
                <Col className="ACH-TreeDetalis-ryt">
                  <Details data={achievements} />
                </Col>
              ) : null}
            </Row>
            <Row className="ACH-BadgeCon">
              <Badges />
            </Row>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverId: state.DriverDetails.driverDetails.Id,
    achievements: state.Achievements.achievements,
    isLoading: state.Achievements.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAchievements: (driverId) => {
      dispatch(fetchAchievements(driverId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
