import {baseURL, ClientKey} from '../../../Services/API';
import {getAuthToken} from '../../../Common/Helper';

const fetchRefreshToken = (email) => (dispatch,getState) => {
  dispatch({
    type: 'FETCH_REFRESH_TOKEN',
    meta: {
      offline: {
        effect: {
          headers:{
            ClientKey:ClientKey,
            UserId:email,
            AccessToken:getAuthToken(),
            Platform: 'Mobile',
          },
          method: 'POST',
          url: `${baseURL}v1/token/login`
        },
        commit: { 
          type: 'SET_REFRESH_TOKEN', 
          meta:{
          } 
        } 
      },
      rollback: {
        type: 'ROLLBACK_REFRESH_TOKEN',
        meta: {  }
      }
    }
  });
} 
  
export default fetchRefreshToken;