const initialState = {
    achievements:[]//localStorage.getItem('tripInfo')? localStorage.getItem('tripInfo') : []
};

function Achievements(state = initialState,action) {
    let achievements = localStorage.getItem('reduxPersist:Achievements') ? JSON.parse(localStorage.getItem('reduxPersist:Achievements')): {}; 
    switch(action.type){
        case 'SET_ACHIEVEMENTS':
            return {achievements:action.payload.data, isLoading:false}
        case 'ROLLBACK_ACHIEVEMENTS':
            return {achievements};
        case 'SET_ACHIEVEMENTS_LOADER_ACTIVE':
            return {achievements, isLoading:true}
        case 'SET_ACHIEVEMENTS_LOADER_DEACTIVE':
            return {achievements, isLoading:false}    
        default:
            return state;
    }
}

export default Achievements;