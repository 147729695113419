import React, { Component, Fragment } from 'react'
import Menu from '../Menu';
import Logo from '../../assets/Logo.svg';
import Sidebarburger from '../Navigation';

class Header extends Component {
    render() {
      return (
        <Fragment>           
          <div  className='header-Container'>

          <Sidebarburger pageWrapId={"page-wrap"} outerContainerId={"App"} />

          <div id="page-wrap">
            <img src={Logo} alt="DBLeafGreen" className="img"/>
          </div>
          <Menu />
          <div className='clearfix'></div> 
        </div>
        </Fragment> 
      );
    }
}


export default Header