import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store';
import './i18n';
//import authentication from 'react-azure-adb2c';
import authentication from '@kdpw/msal-b2c-react';
import decodeJWT from 'jwt-decode';


authentication.initialize({
    // optional, will default to this
    instance: process.env.REACT_APP_INSTANCE, 
    // your B2C tenant
    tenant: process.env.REACT_APP_TENANT,
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: process.env.REACT_APP_SIGNINPOLICY,
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    applicationId: process.env.REACT_APP_APPLICATIONID,
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: process.env.REACT_APP_CACHELOCATION,
    // the scopes you want included in the access token
    scopes: [process.env.REACT_APP_SCOPES],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    redirectUri: process.env.REACT_APP_REDIRECTURI,
    // optional, the URI to redirect to after logout
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: false
});
var emails = [];

authentication.run(() => {
    const token = authentication.getAccessToken();
    const decoded = decodeJWT(token);
    emails = decoded.emails
ReactDOM.render(
    <Provider store={store}>
        <App email={emails}/>
    </Provider>,
 document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
