import React, {Suspense, useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch} from "react-router-dom";
import './App.css';
import Header from './Common/Header';
import {Container} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import MainContainer from './MainContainer';
import fetchRefreshToken from './store/Actions/FETCH_REFRESH_TOKEN';
import {connect} from 'react-redux';


class MainApp extends React.Component {

  componentDidMount(){
    if(this.props.email!==null && this.props.email!==''){
      this.props.getRefreshToken(this.props.email);
    }
  }

  render(){
    return (
      <div>
        <Container className='MainCon-outr'>  
          <Router>      
            <Header />
            <Switch>
              <MainContainer email={this.props.email}/>
            </Switch>
          </Router>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRefreshToken:(email) => dispatch(fetchRefreshToken(email))
  }
}

const MainAppWrapped = connect(null, mapDispatchToProps)(MainApp);

const Loader = () => (
  <div >
    
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App(props) {
  return (
    <Suspense fallback={<Loader />}>
      <MainAppWrapped email={props.email[0]}/>
    </Suspense>
  );
}
