import React from 'react';
import { useTranslation } from 'react-i18next';


function Tip(props) {
    
    const { t } = useTranslation();	
    
    return props.text !== undefined && props.text !== null && props.text !==''  ?
    <div className="TipBox_Content">
        <h5 className="TipHeading" >{t('dashboard_lb_tip')}</h5>
        <p className="TipHeading">{t('tip.'+ props.text.toLowerCase())}</p>
    </div>
    :
    null
}

export default Tip;