import { baseURL, ClientKey } from "../../../Services/API";
import { getAuthToken } from "../../../Common/Helper";

const fetchIncident = (tripId) => (dispatch, getState) => {
  let state = getState();
  let refreshToken = state.AccessToken.refreshToken;
  let email = state.DriverDetails.driverDetails.Email;

  dispatch({
    type: "SET_INCIDENTS_LOADER_ACTIVE",
    meta: { tripId: tripId },
  });
  dispatch({
    type: "FETCH_INCIDENTS",
    meta: {
      offline: {
        effect: {
          headers: {
            ClientKey: ClientKey,
            UserId: email,
            AccessToken: getAuthToken(),
            RandomToken: refreshToken,
          },
          method: "GET",
          url: `${baseURL}v1/incident/getincidents/${tripId}`,
        },
        commit: {
          type: "SET_INCIDENTS",
          meta: {
            tripId: tripId,
          },
        },
      },
      rollback: {
        type: "INCIDENTS_ROLLBACK",
        meta: {},
      },
    },
  });
};

export default fetchIncident;
