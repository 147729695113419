import React, { Fragment } from "react";
import TripInfo from "../../../Common/TripInfo";
import TripDetails from "./TripDetails";

class TripSlide extends React.Component {
  render() {
    return (
      <Fragment>
        <TripInfo
          data={this.props.data}
          next={this.props.next}
          previous={this.props.previous}
          disableRight={this.props.disableRight}
          disableLeft={this.props.disableLeft}
        />
        <TripDetails
          tripId={this.props.data.TripId}
          tripNumber={this.props.tripNumber}
          slideNumber={this.props.slideNumber}
        />
      </Fragment>
    );
  }
}

export default TripSlide;
