const initialState = {
    incidents:{}
};

function Incidents(state = initialState,action) {
    let incidents = localStorage.getItem('reduxPersist:Incidents') ? JSON.parse(localStorage.getItem('reduxPersist:Incidents')): {}; 
    switch(action.type){
        case 'SET_INCIDENTS':
            return {incidents:{...state.incidents,[action.meta.tripId]:{data:action.payload.data,isLoading:false}}}
        case 'TRIP_SCORE_ROLLBACK':
            return incidents;
        case 'SET_INCIDENTS_LOADER_ACTIVE':
            return {incidents:{...state.incidents,[action.meta.tripId]:{isLoading:true}}};
        case 'SET_INCIDENTS_LOADER_DEACTIVE':
        return {incidents:{...state.incidents,[action.meta.tripId]:{isLoading:false}}};
        default:
            return state;
    }   
}

export default Incidents;