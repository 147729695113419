import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import "../Achievements.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

function Achievementsdetails(props) {
  const { t } = useTranslation();

  let data = props.data;
  let averageScore =
    data.AverageScore !== undefined ? data.AverageScore.toFixed(1) : 0;

  let firstpart = Math.trunc(averageScore);
  let lastpoint = (averageScore + "").split(".")[1];

  if (data !== undefined) {
    return (
      <Fragment>
        <Row className="Ach-detail-Crclbar">
          <Col className="Ach-detail-cp">
            <CircularProgressbarWithChildren
              className="CircularProgressbarWithChildren-outr"
              counterClockwise={true}
              value={data.AverageScore * 10}
              strokeWidth={15}
              styles={buildStyles({
                strokeLinecap: "butt",
                rotation: 0.18,
                trailColor: "#B8CDD7",
                pathColor: "#2FBCDF",
              })}
            >
              <div className="DriverScore_Txt">
                <div style={{ fontSize: 16 }}>
                  {data.AverageScore !== undefined ? firstpart : ""}.
                  <span
                    style={{
                      fontSize: 12,
                      display: "contents",
                      fontWeight: "normal",
                    }}
                  >
                    {data.AverageScore !== undefined ? lastpoint : ""}
                  </span>
                </div>
              </div>
            </CircularProgressbarWithChildren>
          </Col>
        </Row>
        <Row>
          <Col className="Ach-detail-placing">
            <p>{t("achievement_lb_1")}</p>
            {data.DriverRank === 0 ? (
              "--"
            ) : (
              <span>
                {data.DriverRank}
                <sup>({data.TotalRank})</sup>
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t("achievement_lb_2")}</p>
            <span>{data.Streak} Trips</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t("achievement_lb_3")}</p>
            <span>
              {data.LastSpeeding} {data.DistanceUom}
            </span>
          </Col>
        </Row>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default Achievementsdetails;
