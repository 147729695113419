import React from 'react'

export function getFormatDate(strDate){

    let strSplitDate = String(strDate).split(' ');
    let date = new Date(strSplitDate[0]);
    let monthLocalizedString = function(month, locale) {
        return new Date(2010,month).toLocaleString(locale,{month:"long"});
    };

    let dd = date.getDate();
    let mm = date.getMonth(); //January is 0!
    let mmName = monthLocalizedString(mm, 'en');    
    let yyyy = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }
    date =  yyyy + " " + mmName + " " + dd;
    return date.toString();
}
export function getDate(strDate){
    let strSplitDate = String(strDate).split(' ');
    let date = new Date(strSplitDate[0]);
    let monthLocalizedString = function(month, locale) {
        return new Date(2010,month).toLocaleString(locale,{month:"long"});
    };

    let dd = date.getDate();
    let mm = date.getMonth()+1; //January is 0!
    let mmName = monthLocalizedString(mm, 'en');    
    let yyyy = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    date =  yyyy + "-" + mm + "-" + dd +" "+strDate.slice(11, 19);
    return date.toString();


}

export function getAuthToken(){
    return localStorage.getItem('adal.idtoken');
}



function Helper() {
    return {
        getFormatDate:getFormatDate,
        getDate:getDate,
        getAuthToken:getAuthToken
    }
}

export default Helper
