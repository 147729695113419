import { baseURL, ClientKey } from "../../../Services/API";
import { getAuthToken } from "../../../Common/Helper";

const fetchTripScore = (tripId, tripNumber) => (dispatch, getState) => {
  let oldState = getState();
  let refreshToken = oldState.AccessToken.refreshToken;
  let email = oldState.DriverDetails.driverDetails.Email;
  let driverId = oldState.DriverDetails.driverDetails.Id;
  dispatch({
    type: "FETCH_TRIP_SCORE",
    meta: {
      offline: {
        effect: {
          headers: {
            ClientKey: ClientKey,
            UserId: email,
            AccessToken: getAuthToken(),
            RandomToken: refreshToken,
          },
          method: "GET",
          url: `${baseURL}v1/trip/gettrip/${tripNumber}/${driverId}`,
        },
        commit: {
          type: "SET_TRIP_SCORE",
          meta: {
            tripId: tripId,
          },
        },
      },
      rollback: {
        type: "TRIP_SCORE_ROLLBACK",
        meta: {},
      },
    },
  });
};

export default fetchTripScore;
