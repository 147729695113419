import React, { Component } from "react";
import { slide as MenuSlider } from "react-burger-menu";
import { NavLink } from "react-router-dom";
//import authentication from 'react-azure-adb2c';
import authentication from "@kdpw/msal-b2c-react";

import { withTranslation } from "react-i18next";
import axios from "axios";
import { baseURL, ClientKey } from "../../Services/API";
import { connect } from "react-redux";
import { getAuthToken } from "../../Common/Helper";

export class Sidebarburger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.logout = this.logout.bind(this);
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  logout = () => {
    axios({
      method: "post",
      headers: {
        ClientKey: ClientKey,
        UserId: this.props.email,
        AccessToken: getAuthToken(),
        RandomToken: this.props.refreshToken,
      },
      url: `${baseURL}/v1/token/logout`,
    })
      .then(function (response) {
        authentication.signOut();
      })
      .catch((err) => null);
  };

  signOut = () => {
    let appVersion = localStorage.getItem("reduxPersist:appVersion");
    localStorage.clear();
    localStorage.setItem("reduxPersist:appVersion", appVersion);
    if (this.props.refreshTokenStatus === 404) {
      authentication.signOut();
    } else {
      this.logout();
    }
  };

  render() {
    const { t } = this.props;
    const isActive = (path, match, location) =>
      !!(match || path === location.pathname);
    return (
      <div>
        <MenuSlider
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <NavLink
            id="home"
            onClick={() => this.closeMenu()}
            activeClassName="active"
            isActive={isActive.bind(this, "/")}
            className="menu-item"
            to="/"
          >
            {t("nav_menu_home")}
          </NavLink>
          <NavLink
            id="about"
            onClick={() => this.closeMenu()}
            activeClassName="active"
            isActive={isActive.bind(this, "/aboutus")}
            className="menu-item"
            to="/aboutus"
          >
            {t("nav_menu_about")}
          </NavLink>
          <NavLink
            id="privacy"
            onClick={() => this.closeMenu()}
            activeClassName="active"
            isActive={isActive.bind(this, "/privacypolicy")}
            className="menu-item"
            to="/privacypolicy"
          >
            {t("nav_menu_privacy")}
          </NavLink>
          <NavLink onClick={this.signOut} className="menu-item" to="">
            {t("nav_menu_logout")}
          </NavLink>
        </MenuSlider>
      </div>
    );
  }
}
export function errLogout(props) {
  let appVersion = localStorage.getItem("reduxPersist:appVersion");
  localStorage.setItem("reduxPersist:appVersion", appVersion);
  try {
    let email = JSON.parse(localStorage.getItem("reduxPersist:DriverDetails"))
      .driverDetails.email;
    let refreshToken = JSON.parse(
      localStorage.getItem("reduxPersist:AccessToken")
    ).refreshToken;
    localStorage.clear();
    axios({
      method: "post",
      headers: {
        ClientKey: ClientKey,
        UserId: email,
        AccessToken: getAuthToken(),
        RandomToken: refreshToken,
      },
      url: `${baseURL}/v1/token/logout`,
    })
      .then(function (response) {
        authentication.signOut();
      })
      .catch(window.location.reload());
  } catch (e) {
    localStorage.clear();
    window.location.reload();
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.DriverDetails.driverDetails.Email,
    refreshToken: state.AccessToken.refreshToken,
    refreshTokenStatus: state.AccessToken.refreshTokenStatus,
  };
};

export default connect(mapStateToProps)(withTranslation()(Sidebarburger));
