import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GreenBar from "../../../Common/GreenBar";
import OrangeBar from "../../../Common/OrangeBar";

function ProgressiveBars(props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="DB_ProgressiveCon">
        {props.incidents.map((incident, k) => {
          return incident.Name !== undefined &&
            incident.Name !== null &&
            incident.Name !== "" ? (
            <Row className="DB_ProgressiveCon_list" key={k}>
              <Col>
                <Row>
                  <Col>
                    <p className="text-center">
                      {t([incident.Name.toLowerCase()])}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingRight: "3px" }}>
                    <div
                      className="progress"
                      style={{ height: "14px", borderRadius: "10px" }}
                    >
                      {OrangeBar(incident.Value)}
                    </div>
                  </Col>

                  <Col style={{ paddingLeft: "3px" }}>
                    <div
                      className="progress"
                      style={{ height: "14px", borderRadius: "10px" }}
                    >
                      {GreenBar(incident.Value)}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null;
        })}
      </div>
    </Fragment>
  );
}

export default ProgressiveBars;
