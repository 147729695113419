import { baseURL, ClientKey } from "../../../Services/API";
import { getAuthToken } from "../../../Common/Helper";
const fetchRecenttrips = (driverId) => (dispatch, getState) => {
  let state = getState();
  let refreshToken = state.AccessToken.refreshToken;
  let email = state.DriverDetails.driverDetails.Email;

  dispatch({ type: "SET_RECENT_TRIP_LOADER_ACTIVE" });
  dispatch({
    type: "FETCH_RECENT_TRIPS",
    meta: {
      offline: {
        effect: {
          method: "GET",
          headers: {
            ClientKey: ClientKey,
            UserId: email,
            AccessToken: getAuthToken(),
            RandomToken: refreshToken,
          },
          url: `${baseURL}v1/trip/getrecenttrips/${driverId}`,
          //   data: updatedRecord
        },
        commit: { type: "SET_RECENT_TRIPS" },
      },
      rollback: {
        type: "ROLLBACK_RECENT_TRIPS",
        meta: {},
      },
    },
  });
};

export default fetchRecenttrips;
