import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import sliderArrow_left from "../../assets/Arrow_left.png";
import sliderArrow_right from "../../assets/Arrow.png";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getFormatDate } from "../../Common/Helper";

class TripInfo extends Component {
  render() {
    const { t } = this.props;
    const props = this.props;
    return (
      <div className="HeaderBox_content">
        <Row>
          {/* <Col xs={2} className='text-center'><span className={props.disableLeft ? 'disableArrow': ''}><img src={sliderArrow_left} alt="sliderArrow_left" className="arrow slider-left" onClick={props.previous} /></span></Col> */}
          <Col xs={2} className="text-center"></Col>
          <Col xs={8} style={{ position: "relative" }}>
            <p className="HeaderTripDate">
              {getFormatDate(props.data.TripDate)}
            </p>
            <p className="HeaderTripNumber">
              {t("dashboard_lb_trip")}: {props.data.TripNumber}
            </p>
            {props.tripScore !== undefined &&
            Object.keys(props.tripScore).length > 0 &&
            props.tripScore[props.data.TripId] !== undefined ? (
              <p className="tripScore">
                {props.tripScore[props.data.TripId].TripScore}/10
              </p>
            ) : null}
          </Col>
          <Col xs={2} className="text-center"></Col>
          {/* <Col xs={2} className='text-center'><span className={props.disableRight ? 'disableArrow': ''}><img src={sliderArrow_right} alt="sliderArrow_right" className="arrow slider-right" onClick={props.next} /></span></Col> */}
          <div className="clearfix"></div>
        </Row>
      </div>
    );
  }
}

const TripInfoMain = withTranslation()(TripInfo);
const mapStateToProps = (state) => {
  return {
    tripScore: state.TripScore.tripScore,
  };
};
export default connect(mapStateToProps)(TripInfoMain);
