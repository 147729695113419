const initialState = {
    refreshToken:'',
    refreshTokenStatus: 200,
};

function AccessToken(state = initialState,action) {
    switch(action.type){
        case 'SET_REFRESH_TOKEN':
            if(action.payload.status === 200){
                return {refreshToken:action.payload.data, refreshTokenStatus: 200};
            }else{
                return {refreshToken:'', refreshTokenStatus: action.payload.response.status};
            }
        case 'ROLLBACK_REFRESH_TOKEN':
            return state;
        case 'SET_REFRESH_LOADER_ACTIVE':
            return state;
        case 'SET_REFRESH_LOADER_DEACTIVE':
            return state;
        default:
            return state;
    }
}

export default AccessToken;