const initialState = {
    briefData:[]
};

function BriefData(state = initialState,action) {
    let briefData = localStorage.getItem('reduxPersist:BriefData') ? JSON.parse(localStorage.getItem('reduxPersist:BriefData')): {}; 
    switch(action.type){
        case 'SET_BRIEFDATA':
            return {briefData:action.payload.data, isLoading:false}
        case 'ROLLBACK_BRIEFDATA':
            return {briefData,isLoading:false};
        case 'SET_BRIEFDATA_LOADER_ACTIVE':
            return {briefData, isLoading:true};
        case 'SET_BRIEFDATA_LOADER_DEACTIVE':
            return {briefData, isLoading:false};
        default:
            return state;
    }   
}

export default BriefData;