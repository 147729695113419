import React, { Fragment } from "react";
import Tip from "./Tip";
import ProgressiveBars from "./ProgressiveBars";
import { Link } from "react-router-dom";
import fetchTripIncident from "../../../store/Actions/FETCH_TRIP_INCIDENT";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class MainTripDesc extends React.Component {
  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.props.getTripIncident(this.props.tripId, this.props.driverId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tripId !== this.props.tripId) {
      this.props.getTripIncident(this.props.tripId, this.props.driverId);
    }
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.props.getTripIncident(this.props.tripId, this.props.driverId);
    }
  }

  render() {
    const { t } = this.props;
    let tripIncident = this.props.tripIncident[this.props.tripId];
    if (tripIncident !== undefined) {
      return (
        <Fragment>
          {Object.keys(tripIncident).length > 0 ? (
            <div>
              {tripIncident.RecommendationText !== "" ? (
                <Tip text={tripIncident.RecommendationText} />
              ) : null}
              <ProgressiveBars incidents={tripIncident.Incidents} />
            </div>
          ) : null}
          <Row>
            <Col className="text-center DB-furtherdetbtn-Con">
              <Link to={"/incidents/" + this.props.slideNumber}>
                <Button>{t("dashboard_btn_further_details")}</Button>
              </Link>
            </Col>
          </Row>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tripIncident: state.TripIncident.tripIncident,
    driverId: state.DriverDetails.driverDetails.Id,
    refreshToken: state.AccessToken.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTripIncident: (tripId, driverId) =>
      dispatch(fetchTripIncident(tripId, driverId)),
  };
};

const TripDesc = withTranslation()(MainTripDesc);

export default connect(mapStateToProps, mapDispatchToProps)(TripDesc);
