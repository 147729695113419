import React, {Fragment} from 'react';
function OrangeBar(value){
    if(value>0){
        return <Fragment>
            <div className="progress-bar" style={{width:(1-value)*100 +'%',height:'14px', backgroundColor: '#cedce3'}}></div>
            <div className="progress-bar" style={{width:value*100 + '%',height:'14px', backgroundColor: '#cedce3', background: 'transparent linear-gradient(to left, #DC7800 0%, #E6A04C 100%) 0% 0% no-repeat padding-box'}}></div>
            </Fragment>
    }else{
        return <div className="progress-bar" style={{width:'100%',height:'14px', backgroundColor: '#cedce3'}}></div>
    }
}

export default OrangeBar;