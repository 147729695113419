import React from 'react';
import { withTranslation } from 'react-i18next';

class privacyPolicy extends React.Component {
    render(){
        const { t } = this.props;	
        return (
            <div className='cmnstatic-pageCon'>
                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h1') }}></h1>
                <p dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p1') }}></p>
                
                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h2') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p2') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h3') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p3') }}></div>
                
                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h4') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p4') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h5') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p5') }}></div>
                
                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h6') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p6') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h7') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p7') }}></div>
                
                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h8') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p8') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h9') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p9') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h10') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p10') }}></div>

                <h1 dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.h11') }}></h1>
                <div dangerouslySetInnerHTML={{ __html: t('privacy_policy_content.p11') }}></div>
            </div>
        )
    }
}

export default withTranslation()(privacyPolicy);
