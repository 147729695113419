import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import fetchBriefData from "../../store/Actions/FETCH_BRIEFDATA";
import "./Briefing.css";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Loader from "../../Common/Loader";

class Briefing extends React.Component {
  state = {
    dataBar: {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [
            "rgba(76, 188, 234, 1)",
            "rgba(76, 188, 234, 1)",
            "rgba(76, 188, 234, 1)",
            "rgba(76, 188, 234, 1)",
            "rgba(11, 165, 15,1)",
            "rgba(255, 255, 255,0.4)",
          ],
          borderWidth: 0,
          // borderColor: [
          //     "rgba(255, 134, 159, 1)",
          //     "rgba(98,  182, 239, 1)",
          //     "rgba(255, 218, 128, 1)",
          //     "rgba(113, 205, 205, 1)",
          //     "rgba(170, 128, 252, 1)",
          //     "rgba(255, 255, 255, 1)"
          // ]
        },
      ],
    },
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            barPercentage: 0.1,
            gridLines: {
              display: false,
              color: "rgba(0, 0, 0, 0.1)",
              //lineWidth: false
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              //lineWidth: false,
              display: false,
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              min: 0,
              max: 10,
              stepSize: 10 / 10,
              beginAtZero: true,
            },
          },
        ],
      },
    },
    activeItem: 1,
    briefData: [],
  };

  componentDidMount() {
    if (this.props.driverId !== undefined) {
      this.props.getBreifData(this.props.driverId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.driverId !== undefined &&
      prevProps.driverId !== this.props.driverId
    ) {
      this.props.getBreifData(this.props.driverId);
    }
    if (
      this.props.briefData !== undefined &&
      prevProps.briefData !== this.props.briefData
    ) {
      let week =
        this.props.briefData.Week !== undefined
          ? this.props.briefData.Week
          : {};
      let i = 0;
      let labels = [];
      let data = [];
      for (i = week.length - 1; i > -1; i--) {
        labels.push(week[i].Key);
        data.push(week[i].Value);
      }
      let new_state = { ...this.state };
      new_state["dataBar"]["labels"] = labels;
      new_state["dataBar"]["datasets"][0]["data"] = data;
      this.setState({
        briefData: this.props.briefData,
        new_state,
      });
    }
  }

  showTab = (tab) => {
    let context =
      this.state.briefData[tab] !== undefined ? this.state.briefData[tab] : {};
    let labels = [];
    let data = [];
    let i = 0;
    for (i = context.length - 1; i > -1; i--) {
      labels.push(context[i].Key);
      data.push(context[i].Value);
    }
    let new_state = { ...this.state };
    new_state["dataBar"]["labels"] = labels;
    new_state["dataBar"]["datasets"][0]["data"] = data;
    this.setState({
      new_state,
      activeItem: tab === "Week" ? 1 : tab === "Month" ? 2 : 3,
    });
  };

  render() {
    const { t } = this.props;
    const isLoading =
      this.props.isLoading !== undefined
        ? this.props.isLoading
        : this.state.isLoading;
    return (
      <Fragment>
        <Helmet>
          <title>Briefing Page</title>
          <meta
            name="description"
            content="A Linde driver companion application Driver Briefing"
          />
        </Helmet>
        <div className="bGraph-con">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="bGraph-con-innr">
              <ul className="list-group .list-group-flush list-group-horizontal ">
                <li
                  onClick={() => this.showTab("Week")}
                  className={
                    "list-group-item " +
                    (this.state.activeItem === 1 ? "active" : "")
                  }
                >
                  {t("briefing_lb_week")}
                  <span></span>
                </li>
                <li
                  onClick={() => this.showTab("Month")}
                  className={
                    "list-group-item " +
                    (this.state.activeItem === 2 ? "active" : "")
                  }
                >
                  {t("briefing_lb_Month")}
                  <span></span>
                </li>
                <li
                  onClick={() => this.showTab("Year")}
                  className={
                    "list-group-item " +
                    (this.state.activeItem === 3 ? "active" : "")
                  }
                >
                  {t("briefing_lb_Year")}
                  <span></span>
                </li>
              </ul>
              <div className="bGraph-con-chart">
                <Bar
                  data={this.state.dataBar}
                  options={this.state.barChartOptions}
                  height={450}
                />
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverId: state.DriverDetails.driverDetails.Id,
    briefData: state.BriefData.briefData,
    isLoading: state.BriefData.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBreifData: (driverId) => dispatch(fetchBriefData(driverId)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Briefing)
);
