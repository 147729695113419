import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import "../Achievements.css";
import ico_driver_of_month from "../../../assets/ico_driver_of_month.svg";
import ico_driver_of_week from "../../../assets/ico_driver_of_week.svg";
import ico_driver_of_year from "../../../assets/ico_driver_of_year.svg";
import fetchBadges from "../../../store/Actions/FETCH_BADGES";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Badges extends Component {
  componentDidMount() {
    if (this.props.driverId !== undefined) {
      this.props.getBadges(this.props.driverId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.driverId !== this.props.driverId) {
      if (this.props.driverId !== undefined) {
        this.props.getBadges(this.props.driverId);
      }
    }
  }

  render() {
    const { t } = this.props;
    let badges = this.props.badges;
    return (
      <Fragment>
        <Col>
          <div className="ACH-BadgeImg">
            <div className="ACH-BadgeImgCon">
              <img
                src={ico_driver_of_week}
                alt="ico_driver_of_week"
                className="img"
              />
              {badges.WeekCount !== undefined ? (
                <span>{badges.WeekCount}</span>
              ) : null}
            </div>
          </div>
          <p>{t("driver_of_the_week")}</p>
        </Col>
        <Col>
          <div className="ACH-BadgeImg">
            <div className="ACH-BadgeImgCon">
              <img
                src={ico_driver_of_month}
                alt="ico_driver_of_month"
                className="img"
              />
              {badges.MonthCount !== undefined ? (
                <span>{badges.MonthCount}</span>
              ) : null}
            </div>
          </div>
          <p>{t("driver_of_the_month")}</p>
        </Col>
        <Col>
          <div className="ACH-BadgeImg">
            <div className="ACH-BadgeImgCon">
              <img
                src={ico_driver_of_year}
                alt="ico_driver_of_year"
                className="img"
              />
              {badges.YearCount !== undefined ? (
                <span> {badges.YearCount}</span>
              ) : null}
            </div>
          </div>
          <p>{t("driver_of_the_year")}</p>
        </Col>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverId: state.DriverDetails.driverDetails.Id,
    badges: state.Badges.badges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBadges: (driverId) => dispatch(fetchBadges(driverId)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Badges)
);
