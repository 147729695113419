const initialState = {
    tripIncident:{}
};

function TripIncident(state = initialState,action) {
    switch(action.type){
        case 'SET_TRIP_INCIDENTS':
            let tripIncident = {};
            tripIncident = {...state.tripIncident,[action.meta.tripId]:action.payload.data}
            return {tripIncident}
        case 'TRIP_INCIDENT_ROLLBACK':
            return state;
        default:
            return state;
    }   
}

export default TripIncident;