import React, { Fragment } from "react";
import TripScore from "./TripScore";
import TripDesc from "./TripDesc";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class MainTripDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  render() {
    const { t } = this.props;
    const details = this.props;
    return (
      <Fragment>
        {this.state.isVisible ? (
          <div>
            <TripScore
              tripId={details.tripId}
              tripNumber={details.tripNumber}
            />
            <TripDesc
              tripId={details.tripId}
              slideNumber={details.slideNumber}
            />
          </div>
        ) : (
          <Row className="TripScore_content">
            <Col style={{ textAlign: "justify" }}>
              {t("dashboard_lb_noDataMessage")}
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}
const TripDetails = withTranslation()(MainTripDetails);
export default TripDetails;
