import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { connect } from "react-redux";
import TripSlide from "./TripSlide";
import { withTranslation } from "react-i18next";
import Loader from "../../../Common/Loader";

class MainSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      isLoading: true,
    };
  }
  // componentDidMount(){
  //     window.addEventListener('touchmove', this.preventDefault, {passive: false});
  // }
  //  componentWillUnmount(){
  //     window.removeEventListener('touchmove', this.preventDefault, {passive: false});

  //   //  preventDefault = (e) => { if(this.state.swiping) { e.preventDefault(); e.returnValue = false; return false; } };
  //  }
  componentDidCatch(e) {
    console.log(e);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  renderSlides = (initialSlideHandler) => {
    return this.props.tripInfo.map((value, k) => {
      return (
        <div data-index={k} key={k}>
          <TripSlide
            data={value}
            next={this.next}
            previous={this.previous}
            disableRight={initialSlideHandler === k}
            disableLeft={k === 0}
            tripNumber={value.TripNumber}
            slideNumber={k}
          />
        </div>
      );
    });
  };

  render() {
    const { t } = this.props;
    const isLoading =
      this.props.isLoading !== undefined
        ? this.props.isLoading
        : this.state.isLoading;
    const tripInfo = this.props.tripInfo;

    let initialSlideHandler = tripInfo.length > 1 ? tripInfo.length - 1 : 0;

    let settings = {
      dots: true,
      infinite: false,
      speed: 500,
      initialSlide: initialSlideHandler,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: false,
      edgeFriction: 0,
      swipe: true,
      beforeChange: (oldIndex, newIndex) => {
        if (newIndex == 0 || oldIndex == tripInfo.length - 1) {
          settings.swipe = false;
        } else {
          settings.swipe = true;
        }
      },
      afterChange: (index) => {},
    };
    return (
      <Container>
        {this.props.refreshTokenStatus === 404 ? (
          <Row className="TripScore_content">
            <h3
              className="col-12"
              style={{
                animation: "loading 10s linear",
                color: "red",
                fontSize: "22px",
              }}
            >
              User does not have role to Access
            </h3>
          </Row>
        ) : isLoading ? (
          <Loader />
        ) : tripInfo.length > 0 ? (
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.renderSlides(initialSlideHandler)}
          </Slider>
        ) : (
          <Row className="TripScore_content">
            <Col style={{ textAlign: "justify" }}>
              {t("dashboard_lb_noDataMessage")}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.RecentTrips.isLoading,
    tripInfo: state.RecentTrips.tripInfo,
    refreshTokenStatus: state.AccessToken.refreshTokenStatus,
  };
};

const MainSliderTranslated = withTranslation()(MainSlider);
export default connect(mapStateToProps)(MainSliderTranslated);
