import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TripInfo from "../../../Common/TripInfo";
import IncidentContainer from "./IncidentContainer";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "../../../Common/Loader";

class MainSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      toggle: false,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidCatch(e) {
    console.log(e);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  renderSlides = (slideLength) => {
    return this.props.tripInfo.map((value, k) => {
      return (
        <div data-index={k} key={k}>
          <TripInfo
            data={value}
            next={this.next}
            previous={this.previous}
            disableRight={slideLength === k}
            disableLeft={k === 0}
          />
          <IncidentContainer tripId={value.TripId} toggle={this.state.toggle} />
        </div>
      );
    });
  };

  render() {
    const { t } = this.props;
    const isLoading =
      this.props.isLoading !== undefined
        ? this.props.isLoading
        : this.state.isLoading;
    let initialSlideHandler =
      this.props.match.params.slideNumber !== undefined &&
      this.props.match.params.slideNumber !== ""
        ? Number(this.props.match.params.slideNumber)
        : this.props.tripInfo.length > 1
        ? this.props.tripInfo.length - 1
        : 0;
    let slideLength =
      this.props.tripInfo.length > 1 ? this.props.tripInfo.length - 1 : 0;
    let settings = {
      dots: true,
      infinite: false,
      speed: 250,
      initialSlide: initialSlideHandler,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      swipe: false,
      draggable: false,
      beforeChange: (oldIndex, newIndex) => {},
      afterChange: (index) => {},
    };

    return (
      <Container>
        {isLoading ? (
          <Loader />
        ) : this.props.tripInfo.length > 0 ? (
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.renderSlides(slideLength)}
          </Slider>
        ) : (
          <Row className="TripScore_content">
            <Col style={{ textAlign: "justify" }}>
              {t("dashboard_lb_noDataMessage")}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tripInfo: state.RecentTrips.tripInfo,
    isLoading: state.RecentTrips.isLoading,
  };
};
const MainSliderTranslated = withTranslation()(MainSlider);
export default connect(mapStateToProps)(MainSliderTranslated);
