const initialState = {
    driverDetails:[]//localStorage.getItem('tripInfo')? localStorage.getItem('tripInfo') : []
};

function DriverDetails(state = initialState,action) {
  let driverDetails = localStorage.getItem('reduxPersist:DriverDetails') ? JSON.parse(localStorage.getItem('reduxPersist:DriverDetails')): {};
    switch(action.type){
        case 'SET_DRIVER_DETAILS':
            return {driverDetails:action.payload.data}
        case 'ROLLBACK_DRIVER_DETAILS':
            return driverDetails;
        default:
            return state;
    }   
}

export default DriverDetails;