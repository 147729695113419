import {createStore,applyMiddleware,compose} from 'redux';
import Reducer from './Reducers';
import thunk from 'redux-thunk';


import axios from 'axios';
import { offline } from "redux-offline";
import config from "redux-offline/lib/defaults";
import {persistStore} from 'redux-persist'; 
import { version } from '../../package.json';
import {defaultQueue} from 'redux-offline';
import {errLogout} from "../Common/Navigation";


const effect = (effect, _action) => axios(effect).then(res=> res).catch(err => {
    const {request, response} = err;
    if( response.status == 400 || response.status == 401 ){
       errLogout();
    }

    return err;
});
const discard = (error, _action, _retries) => {
    const { request, response } = error;
   switch(_action.type){
        case "FETCH_ACHIEVEMENTS":
            store.dispatch({type:'SET_ACHIEVEMENTS_LOADER_DEACTIVE'});
            break;
        case "FETCH_BRIEFDATA":
            store.dispatch({type:'SET_BRIEFDATA_LOADER_DEACTIVE'});
            break;
        default:
            break;
    }
  
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  return 400 <= response.status && response.status < 500;
};


const persistStorage = (store, options, callback) => {
    let appVersion =localStorage.getItem('reduxPersist:appVersion');
      
    const getPersistedStore = () => persistStore(
        store,
        // { storage: localStorage },
        callback,
    );
    const currentAppVersion = version;
    
    if (appVersion) {
        // If version is identified
        const app = JSON.parse(appVersion);
        if (app && app.version !== currentAppVersion) {
            localStorage.clear();
            getPersistedStore().purge();
            localStorage.setItem(
                'reduxPersist:appVersion',
                JSON.stringify({ version: currentAppVersion }),
            );
        } else {
            getPersistedStore(); // .purge to clean the offline data
        }
    } else {
        // If no, define one
        localStorage.setItem(
            'reduxPersist:appVersion',
            JSON.stringify({ version: currentAppVersion }),
        );
    }
};

const getMethod = action => action.meta.offline.effect.method || "GET";
const getUrl = action => action.meta.offline.effect.url;
const reduxOfflineConfig = {
    ...config,
    persist: persistStorage,
    effect,
    discard
}

const logMiddleware = ({ getState, dispatch }) => (next) => (action) => {
    console.log(`Action: ${action.type}`);
    next(action);
};

const store = createStore(Reducer,
    compose(
        offline(reduxOfflineConfig),
        applyMiddleware(thunk)
    )
);
export default store;
