import React from "react";
import Dashboard from "./Screens/Dashboard";
import Briefing from "./Screens/Briefing";
import Incidents from "./Screens/Incidents";
import Achievements from "./Screens/Achievements";
import aboutus from "./Common/Navigation/aboutUs";
import privacypolicy from "./Common/Navigation/privacyPolicy";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import fetchRecenttrips from "./store/Actions/FETCH_RECENT_TRIPS";
import fetchDriverDetails from "./store/Actions/FETCH_DRIVER_DETAILS";
import i18next from "i18next";
import Pullable from "react-pullable";

class MainContainer extends React.Component {
  handleRefresh = (resolve, reject) => {
    this.props.getrecenttrips(this.props.driverId);
  };

  componentDidMount() {
    if (this.props.refreshToken !== "") {
      this.props.getDriverDetails(this.props.email);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.driverId !== null &&
      this.props.driverId !== undefined &&
      this.props.driverId !== ""
    ) {
      if (
        prevProps.driverId !== this.props.driverId &&
        this.props.refreshToken !== ""
      ) {
        this.props.getrecenttrips(this.props.driverId);
      }
    }
    if (
      this.props.languageName !== undefined &&
      prevProps.languageName !== this.props.languageName
    ) {
      i18next.changeLanguage(this.props.languageName);
    } else if (prevProps.languageName !== this.props.languageName) {
      i18next.changeLanguage();
    }
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.props.getDriverDetails(this.props.email, this.props.refreshToken);
    }
  }

  render() {
    return (
      <Pullable
        onRefresh={() => this.handleRefresh()}
        centerSpinner={true}
        spinnerColor={"#000000"}
        spinnerSize={25}
        fadeSpinner={true}
        // popDuration={50}
        rotateSpinner={true}
        spinnerOffset={100}
        // refreshDuration={700}
      >
        <div
          className="MainContent"
          id={this.props.tripInfo.length === 0 ? "NoContent-Con" : null}
        >
          <Route path="/" exact component={Dashboard} />
          <Route path="/briefing" exact component={Briefing} />
          <Route path="/incidents/:slideNumber?" exact component={Incidents} />
          <Route path="/achievements" exact component={Achievements} />
          <Route path="/aboutus" exact component={aboutus} />
          <Route path="/privacypolicy" exact component={privacypolicy} />
          <div className="clearfix"></div>
        </div>
      </Pullable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverId: state.DriverDetails.driverDetails.Id,
    languageName: state.DriverDetails.driverDetails.LanguageName,
    tripInfo: state.RecentTrips.tripInfo,
    refreshToken: state.AccessToken.refreshToken,
    refreshTokenStatus: state.AccessToken.refreshTokenStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getrecenttrips: (driverId) => dispatch(fetchRecenttrips(driverId)),
    getDriverDetails: (email, refreshToken) =>
      dispatch(fetchDriverDetails(email, refreshToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
