import React, { Component } from 'react'
import MainSlider from './Components/MainSlider';
import { Helmet } from 'react-helmet';

class Incidents extends Component {
    render() {
        return (
            <div>
                <Helmet>
                <title>Incidents Page</title>
                <meta
                    name="description"
                    content="A Linde driver companion application Driver Incidents"
                />
                </Helmet>
                 <MainSlider {...this.props}/> 
            </div>
        )
    }
}

export default Incidents
