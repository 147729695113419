import React from 'react';
import tree1 from '../../../assets/treesvg/Tree_1.svg'; 
import tree2 from '../../../assets/treesvg/Tree_2.svg'; 
import tree3 from '../../../assets/treesvg/Tree_3.svg'; 
import tree4 from '../../../assets/treesvg/Tree_4.svg'; 
import tree5 from '../../../assets/treesvg/Tree_5.svg'; 
import tree6 from '../../../assets/treesvg/Tree_6.svg'; 
import tree7 from '../../../assets/treesvg/Tree_7.svg'; 
import tree8 from '../../../assets/treesvg/Tree_8.svg'; 
import tree9 from '../../../assets/treesvg/Tree_9.svg'; 
import tree10 from '../../../assets/treesvg/Tree_10.svg'; 
import tree11 from '../../../assets/treesvg/Tree_11.svg'; 
import tree12 from '../../../assets/treesvg/Tree_12.svg'; 
import tree13 from '../../../assets/treesvg/Tree_13.svg'; 
import tree14 from '../../../assets/treesvg/Tree_14.svg'; 
import tree15 from '../../../assets/treesvg/Tree_15.svg'; 
import tree16 from '../../../assets/treesvg/Tree_16.svg'; 
import tree17 from '../../../assets/treesvg/Tree_17.svg'; 
import tree18 from '../../../assets/treesvg/Tree_18.svg'; 
import tree19 from '../../../assets/treesvg/Tree_19.svg'; 
import tree20 from '../../../assets/treesvg/Tree_20.svg'; 
import tree21 from '../../../assets/treesvg/Tree_21.svg'; 
import tree22 from '../../../assets/treesvg/Tree_22.svg'; 
import tree23 from '../../../assets/treesvg/Tree_23.svg'; 
import tree24 from '../../../assets/treesvg/Tree_24.svg'; 
import tree25 from '../../../assets/treesvg/Tree_25.svg'; 
import tree26 from '../../../assets/treesvg/Tree_26.svg'; 
import tree27 from '../../../assets/treesvg/Tree_27.svg'; 
import tree28 from '../../../assets/treesvg/Tree_28.svg'; 
import tree29 from '../../../assets/treesvg/Tree_29.svg'; 
import tree30 from '../../../assets/treesvg/Tree_30.svg'; 
import tree31 from '../../../assets/treesvg/Tree_31.svg'; 
import tree32 from '../../../assets/treesvg/Tree_32.svg'; 
import tree33 from '../../../assets/treesvg/Tree_33.svg'; 
import tree34 from '../../../assets/treesvg/Tree_34.svg'; 
import tree35 from '../../../assets/treesvg/Tree_35.svg'; 
import tree36 from '../../../assets/treesvg/Tree_36.svg'; 
import tree37 from '../../../assets/treesvg/Tree_37.svg'; 
import tree38 from '../../../assets/treesvg/Tree_38.svg'; 
import tree39 from '../../../assets/treesvg/Tree_39.svg'; 
import tree40 from '../../../assets/treesvg/Tree_40.svg'; 
import tree41 from '../../../assets/treesvg/Tree_41.svg'; 
import tree42 from '../../../assets/treesvg/Tree_42.svg'; 
import tree43 from '../../../assets/treesvg/Tree_43.svg'; 
import tree44 from '../../../assets/treesvg/Tree_44.svg'; 
import tree45 from '../../../assets/treesvg/Tree_45.svg'; 
import tree46 from '../../../assets/treesvg/Tree_46.svg'; 
import tree47 from '../../../assets/treesvg/Tree_47.svg'; 
import tree48 from '../../../assets/treesvg/Tree_48.svg'; 
import tree49 from '../../../assets/treesvg/Tree_49.svg'; 
import tree50 from '../../../assets/treesvg/Tree_50.svg'; 
import tree51 from '../../../assets/treesvg/Tree_51.svg'; 
import tree52 from '../../../assets/treesvg/Tree_52.svg'; 
import tree53 from '../../../assets/treesvg/Tree_53.svg'; 
import tree54 from '../../../assets/treesvg/Tree_54.svg'; 
import tree55 from '../../../assets/treesvg/Tree_55.svg'; 
import tree56 from '../../../assets/treesvg/Tree_56.svg'; 
import tree57 from '../../../assets/treesvg/Tree_57.svg'; 
import tree58 from '../../../assets/treesvg/Tree_58.svg'; 
import tree59 from '../../../assets/treesvg/Tree_59.svg'; 
import tree60 from '../../../assets/treesvg/Tree_60.svg'; 
import tree61 from '../../../assets/treesvg/Tree_61.svg'; 
import tree62 from '../../../assets/treesvg/Tree_62.svg'; 
import tree63 from '../../../assets/treesvg/Tree_63.svg'; 
import tree64 from '../../../assets/treesvg/Tree_64.svg'; 
import tree65 from '../../../assets/treesvg/Tree_65.svg'; 
import tree66 from '../../../assets/treesvg/Tree_66.svg'; 
import tree67 from '../../../assets/treesvg/Tree_67.svg'; 
import tree68 from '../../../assets/treesvg/Tree_68.svg'; 
import tree69 from '../../../assets/treesvg/Tree_69.svg'; 
import tree70 from '../../../assets/treesvg/Tree_70.svg'; 
import tree71 from '../../../assets/treesvg/Tree_71.svg'; 
import tree72 from '../../../assets/treesvg/Tree_72.svg'; 
import '../Achievements.css';

function Tree(props) {
    let treeArray = [
        tree1,tree2,tree3,tree4,tree5,tree6,tree7,tree8,tree9,tree10,
        tree11,tree12,tree13,tree14,tree15,tree16,tree17,tree18,tree19,tree20,
        tree21,tree22,tree23,tree24,tree25,tree26,tree27,tree28,tree29,tree30,
        tree31,tree32,tree33,tree34,tree35,tree36,tree37,tree38,tree39,tree40,
        tree41,tree42,tree43,tree44,tree45,tree46,tree47,tree48,tree49,tree50,
        tree51,tree52,tree53,tree54,tree55,tree56,tree57,tree58,tree59,tree60,
        tree61,tree62,tree63,tree64,tree65,tree66,tree67,tree68,tree69,tree70,
        tree71,tree72
    ];
    let leafScore = props.leafScore;
    return(
        <div>
            <img src={leafScore<71? treeArray[leafScore]: tree72} alt='achievement tree' className="img"/>
        </div>
    )        
}

export default Tree
