import {combineReducers} from 'redux';
import RecentTrips from './RecentTrips';
import DriverDetails from './DriverDetails';
import TripScore from './TripScore';
import TripIncident from './TripIncident';
import Incidents from './Incidents';
import Achievements from './Achievements';
import Badges from './Badges';
import BriefData from './BriefData';
import AccessToken from './AccessToken';

export default combineReducers({DriverDetails, RecentTrips, TripScore, TripIncident, Incidents, Achievements, Badges, BriefData, AccessToken})
