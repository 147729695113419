import React, {Fragment} from 'react';
function GreenBar(value){
    if(value<0){
        return <Fragment>
            <div className="progress-bar" style={{width:Math.abs(value)*100 +'%',height:'14px', backgroundColor: '#cedce3', background: 'transparent linear-gradient(90deg, #009B3C 0%, #4CB976 100%) 0% 0% no-repeat padding-box'}}></div>
            <div className="progress-bar" style={{width:(1-Math.abs(value))*100 +'%',height:'14px', backgroundColor: '#cedce3'}}></div>
            </Fragment>
    }else{
        return <div className="progress-bar" style={{width:'100%',height:'14px', backgroundColor: '#cedce3'}}></div>
    }
}

export default GreenBar;