import React, { Component } from 'react';
import { withTranslation} from 'react-i18next';

class aboutUsMain extends Component {

    render(){
        const {t} = this.props; 
        return (
        <div className='cmnstatic-pageCon'>
            <h1>{t('about_us_heading')}</h1>
            {t('about_us_content')}
        </div>
        )
    }
    
}

const aboutUs = withTranslation()(aboutUsMain);
export default aboutUs
