import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class MainMenu extends Component {
  render() {
    const isActive = (path, match, location) =>
      !!(match || path === location.pathname);
    const { t } = this.props;
    return (
      <div>
        {this.props.tripInfo !== undefined &&
        this.props.tripInfo.length > 0 &&
        this.props.refreshTokenStatus === 200 ? (
          <ul
            className={
              this.props.languageName
                ? this.props.languageName + " " + "menu-OuterCon"
                : "menu-OuterCon"
            }
          >
            <li>
              <NavLink
                exact={true}
                to="/"
                activeClassName="active"
                isActive={isActive.bind(this, "/")}
              >
                {t("menu_lb_dashboard")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/briefing"
                activeClassName="active"
                isActive={isActive.bind(this, "/briefing")}
              >
                {t("menu_lb_briefing")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/incidents"
                activeClassName="active"
                isActive={
                  isActive.bind(this, "/incidents") ||
                  this.props.location.pathname.includes("incidents")
                }
              >
                {t("menu_lb_incidents")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/achievements"
                activeClassName="active"
                isActive={isActive.bind(this, "/achievements")}
              >
                {t("menu_lb_achievements")}
              </NavLink>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

const Menu = withRouter(withTranslation()(MainMenu));
const mapStateToProps = (state) => {
  return {
    languageName: state.DriverDetails.driverDetails.LanguageName,
    tripInfo: state.RecentTrips.tripInfo,
    refreshTokenStatus: state.AccessToken.refreshTokenStatus,
  };
};
export default connect(mapStateToProps)(Menu);
