const initialState = {
    tripScore:{}
};

function TripScore(state = initialState,action) {
    switch(action.type){
        case 'SET_TRIP_SCORE':
            let tripScore = {};
            tripScore = {...state.tripScore,[action.meta.tripId]:action.payload.data}
            return {tripScore}
        case 'TRIP_SCORE_ROLLBACK':
            return state;
        default:
            return state;
    }   
}

export default TripScore;