import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Leaf from "./Leaf";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import fetchTripScore from "../../../store/Actions/FETCH_TRIP_SCORE";

class MainTripScore extends React.Component {
  componentDidMount() {
    this.props.getTripScore(this.props.tripId, this.props.tripNumber);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tripId !== this.props.tripId) {
      this.props.getTripScore(this.props.tripId, this.props.tripNumber);
    }
  }

  setTripScore = (tripScore) => {
    this.props.setTripScore(tripScore);
  };

  render() {
    const { t } = this.props;
    const tripScore = this.props.tripScore[this.props.tripId];
    if (tripScore !== undefined) {
      const DriverScorePercentaage = tripScore.DriverScore * 10;
      const AvgTripScorePercentaage = tripScore.AverageScore * 10;
      const DriverScore = tripScore.DriverScore;
      const AverageTripScore = tripScore.AverageScore;
      return (
        <Row className="TripScore_content">
          {Object.keys(tripScore).length > 0 ? (
            <Fragment>
              <Col xs={5}>
                <CircularProgressbarWithChildren
                  counterClockwise={true}
                  className="CircularProgressbarWithChildren-outr"
                  value={DriverScorePercentaage}
                  strokeWidth={12}
                  styles={buildStyles({ strokeLinecap: "butt" })}
                >
                  <CircularProgressbarWithChildren
                    className="CircularProgressbarWithChildren-Con"
                    counterClockwise={true}
                    value={AvgTripScorePercentaage}
                    strokeWidth={15}
                    styles={buildStyles({ strokeLinecap: "butt" })}
                  >
                    <div className="DriverScore_Txt">
                      <strong>{`${DriverScore}/10`}</strong>
                    </div>
                    <div className="AverageTripScore_Txt">
                      <strong>{`${AverageTripScore}`}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </CircularProgressbarWithChildren>
              </Col>
              <Col xs={7}>
                <div className="UOM-details">
                  <p className="TripScore_Driven_Distance">
                    {tripScore.Distance} {tripScore.DistanceUOM}
                    <span>/</span>
                    {tripScore.Volume} {tripScore.VolumeUOM}
                  </p>
                  <p className="TripScore_Txt_Fuel_Consumed">
                    {t("dashboard_lb_fuel_consumption")}
                  </p>
                  <p className="TripScore_Fuel_Consumed">
                    {tripScore.FuelConsumption} {tripScore.FuelConsumptionUOM}
                  </p>
                </div>
                <Leaf leafScore={tripScore.LeafScore} />
              </Col>
            </Fragment>
          ) : null}
        </Row>
      );
    } else {
      return null;
    }
  }
}

const TripScore = withTranslation()(MainTripScore);

const mapStateToProps = (state) => {
  return {
    // driverId:state.DriverDetails.driverDetails.id,
    tripScore: state.TripScore.tripScore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTripScore: (tripId, tripNumber) =>
      dispatch(fetchTripScore(tripId, tripNumber)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TripScore);
