import { baseURL, ClientKey } from "../../../Services/API";
import { getAuthToken } from "../../../Common/Helper";

const fetchTripIncident = (tripId, driverId) => (dispatch, getState) => {
  let state = getState();
  let refreshToken = state.AccessToken.refreshToken;
  let email = state.DriverDetails.driverDetails.Email;

  dispatch({
    type: "FETCH_TRIP_INCIDENTS",
    meta: {
      offline: {
        effect: {
          headers: {
            ClientKey: ClientKey,
            UserId: email,
            AccessToken: getAuthToken(),
            RandomToken: refreshToken,
          },
          method: "GET",
          url: `${baseURL}v1/trip/getincidents/${tripId}`,
        },
        commit: {
          type: "SET_TRIP_INCIDENTS",
          meta: {
            tripId: tripId,
          },
        },
      },
      rollback: {
        type: "TRIP_INCIDENTS_ROLLBACK",
        meta: {},
      },
    },
  });
};

export default fetchTripIncident;
