import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../Incidents.css";
import { useTranslation } from "react-i18next";
import { getDate } from "../../../Common/Helper";

const MapIncident = (props) => {
  const { t } = useTranslation();
  let map_data = props.map_data;
  let incident_data = props.incident_data;
  const Leaflet = window.L;
  let numberofPosition = map_data.length;
  let firstPosition = [map_data[0].Latitude, map_data[0].Longitude];
  let lastPosition =
    numberofPosition > 1
      ? [
          map_data[numberofPosition - 1].Latitude,
          map_data[numberofPosition - 1].Longitude,
        ]
      : [map_data[0].Latitude + 1, map_data[0].Longitude + 1];

  if (firstPosition.toString() === lastPosition.toString()) {
    lastPosition = [
      map_data[numberofPosition - 1].Latitude + 1,
      map_data[numberofPosition - 1].Longitude + 1,
    ];
  }

  let mapList = [];
  if (numberofPosition > 1) {
    for (let i = 0; i < numberofPosition; i++) {
      mapList.push([map_data[i].Latitude, map_data[i].Longitude]);
    }
  } else {
    mapList = Leaflet.latLngBounds([firstPosition, lastPosition]);
  }
  //const bounds = Leaflet.latLngBounds([firstPosition,lastPosition]);
  return (
    <Map bounds={mapList} maxZoom={19}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {map_data.map((item, idx) => {
        var position;
        var incidentColor;
        function findIndex(jsonData, findThis) {
          var indexNum = jsonData.findIndex(
            (obj) => obj.IncidentName === findThis
          );
          position = indexNum + 1;
          incidentColor =
            indexNum < 20 ? props.incidentColorArray[indexNum] : "#000000";
        }
        findIndex(incident_data, item.EventId);

        const icon = new L.DivIcon({
          className: "my-div-icon",
          html:
            '<div style="background-color:' +
            incidentColor +
            ';transform: rotate(-45deg);padding: 2px; height: 29px; width: 29px; border: 2px solid #fff;"><div  style="color:white; margin :-20px 0 0 -20px,left: 50% ,top : 50%,color:white; padding:2px; height: 20px; width: 20px;  border-radius:  50% 50% 50% 0; display: inline-block;text-align: center;  position : absolute" ><div style="transform: rotate(45deg);">' +
            position +
            "</div></div></div>",
        });
        let eventId =
          item.EventId === null ||
          item.EventId === "" ||
          item.EventId === undefined
            ? t(item.EventId)
            : t(item.EventId.toLowerCase());
        return (
          <Marker
            key={idx}
            icon={icon}
            position={{ lat: item.Latitude, lng: item.Longitude }}
          >
            <Popup>
              {t("incidents_lb_eventName")}: {eventId} <br />
              {t("incidents_lb_eventValue")}: {item.EventValue} <br />
              {t("incidents_lb_timeStamp")}: {getDate(item.TimeStamp)}
            </Popup>
          </Marker>
        );
      })}
    </Map>
  );
};

export default MapIncident;
