import { baseURL, ClientKey } from "../../../Services/API";
import { getAuthToken } from "../../../Common/Helper";

const fetchAchievements = (driverId) => (dispatch, getState) => {
  let state = getState();
  let refreshToken = state.AccessToken.refreshToken;
  let email = state.DriverDetails.driverDetails.Email;

  dispatch({ type: "SET_ACHIEVEMENTS_LOADER_ACTIVE" });
  dispatch({
    type: "FETCH_ACHIEVEMENTS",
    meta: {
      offline: {
        effect: {
          headers: {
            ClientKey: ClientKey,
            UserId: email,
            AccessToken: getAuthToken(),
            RandomToken: refreshToken,
          },
          method: "GET",
          url: `${baseURL}v1/achievement/get/${driverId}`,
        },
        commit: {
          type: "SET_ACHIEVEMENTS",
          meta: {},
        },
      },
      rollback: {
        type: "ROLLBACK_ACHIEVEMENTS",
        meta: {},
      },
    },
  });
};

export default fetchAchievements;
