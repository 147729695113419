import axios from 'axios';

export const baseURL= process.env.REACT_APP_API_URL;
export const ClientKey = process.env.REACT_APP_CLIENT_KEY;

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json"
});

