import React from "react";
import DBLeafGreen from "../../../assets/Leaf_green.svg";
import DBLeafgrey from "../../../assets/Leaf_grey.svg";
import DBLeaforgane from "../../../assets/Leaf_orange.svg";

function setLeaf(leafScore) {
  return [1, 2, 3, 4, 5].map((num) => {
    if (leafScore > 0) {
      leafScore--;
      return (
        <img src={DBLeafGreen} alt="DBLeafGreen" className="img" key={num} />
      );
    } else if (leafScore < 0) {
      leafScore++;
      return (
        <img src={DBLeaforgane} alt="DBLeafGreen" className="img" key={num} />
      );
    } else {
      return (
        <img src={DBLeafgrey} alt="DBLeafGreen" className="img" key={num} />
      );
    }
  });
}

function Leaf(props) {
  let leafScore = props.leafScore;
  return (
    <>
      <div className="DB-leafCon">
        <div className="DB-leafinnr clearfix">
          <div
            id={props.leafScore === 0 ? "SupVishidden" : ""}
            className={
              props.leafScore > 0
                ? "DB-leaf-Green DB-leaf-counter"
                : "DB-leaf-Orange DB-leaf-counter"
            }
          >
            {props.leafScore !== 0 ? props.leafScore : ""}
          </div>
          <div className="DB-leaf-ImgCon">{setLeaf(leafScore)}</div>
        </div>
      </div>
    </>
  );
}

export default Leaf;
